<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="relative flex flex-row justify-center min-h-screen w-full bg-white">
    <div class="w-full max-w-[1600px] flex flex-col">
      <router-view />
      <transition name="fade" mode="out-in">
        <component :is="comp" :key="$route.path" />
      </transition>
      <DialogManager />
      <DialogBox v-if="dialogConfig && dialogConfig.visible" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import useAppStore from './store/useAppStore'
  //import { useRouter } from 'vue-router'
  import type { Component } from 'vue'
  import DialogManager from '@/components/dialog/DialogManager.vue'
  import DialogBox from '@/components/dialog/DialogBox.vue'

  const appStore = useAppStore()
  const dialogConfig = appStore.getters.dialogConfig
  /*   const router = useRouter()

  // If we arrived here from a page reload, make sure to set up again
  if (router && router.currentRoute.value.path !== '/') {
    router.push('/postlogin')
  } */
  appStore.actions.detectOldApp()
  let comp: Component
</script>

<style lang="postcss">
  html {
    @apply font-montserrat;
    @apply font-inter;
  }
  .fadeInOut {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  /* ---- Transition effects available throughout the app ---- */

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .slidenext-enter-from {
    transform: translateX(100%);
  }
  .slidenext-enter-active,
  .slidenext-leave-active {
    transition: all 0.25s ease-out;
  }
  .slidenext-leave-to {
    transform: translateX(-100%);
  }

  .slideprev-enter-from {
    transform: translateX(-100%);
  }
  .slideprev-enter-active,
  .slideprev-leave-active {
    transition: all 0.25s ease-out;
  }
  .slideprev-leave-to {
    transform: translateX(100%);
  }

  .slideup-enter-from {
    transform: translateY(100%);
  }
  .slideup-enter-active,
  .slideup-leave-active {
    transition: all 0.25s ease-out;
  }
  .slideup-leave-to {
    transform: translateY(-100%);
  }
  /* @media screen and (prefers-reduced-motion: reduce) {
    .next-enter {
      opacity: 0;
      transform: translate3d(100px, 0, 0);
    }
    .next-enter-active,
    .next-leave-active {
      transition: 0.5s;
    }
    .next-leave-to {
      opacity: 0;
      transform: translate3d(-100px, 0, 0);
    }

    .prev-enter {
      opacity: 0;
      transform: translate3d(-100px, 0, 0);
    }
    .prev-enter-active,
    .prev-leave-active {
      transition: 0.5s;
    }
    .prev-leave-to {
      opacity: 0;
      transform: translate3d(100px, 0, 0);
    }
  } */
  .slideInRight {
    -webkit-animation: slideInRight 0.25s 0.25s both;
    animation: slideInRight 0.25s 0.25s both;
  }
  @keyframes slideInRight {
    0% {
      -webkit-transform: translateX(20%);
      transform: translateX(20%);
      opacity: 0;
    }
    70% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      opacity: 1;
    }
  }
</style>
